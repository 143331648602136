export const SignInURL = "https://budgetboy.auth.us-east-1.amazoncognito.com/login?client_id=1k6ld9m89ikfp4nptvshj5aqd&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=https://budgetboy.net/DefaultBudget"
export const CreateBudgetURL = "https://budgetboy.net/CreateBudget"
export const EditBudgetURL = "https://budgetboy.net/EditBudget"
export const DefaultBudgetURL = "https://budgetboy.net/DefaultBudget"
export const SignOutURL = "https://budgetboy.auth.us-east-1.amazoncognito.com/logout?client_id=1k6ld9m89ikfp4nptvshj5aqd&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=https://budgetboy.net/DefaultBudget"
export const MyBudgetsURL = "https://budgetboy.net/MyBudgets"
export const AccountURL = "https://budgetboy.net/Account"

// export const SignInURL = "https://budgetboy.auth.us-east-1.amazoncognito.com/login?client_id=1k6ld9m89ikfp4nptvshj5aqd&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=https://budgetboy.net/DefaultBudget"
// export const CreateBudgetURL = "http://localhost:3000/CreateBudget"
// export const EditBudgetURL = "http://localhost:3000/EditBudget"
// export const DefaultBudgetURL = "http://localhost:3000/DefaultBudget"
// export const SignOutURL = "https://budgetboy.auth.us-east-1.amazoncognito.com/logout?client_id=1k6ld9m89ikfp4nptvshj5aqd&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=https://budgetboy.net/DefaultBudget"
// export const MyBudgetsURL = "http://localhost:3000/MyBudgets"
// export const AccountURL = "http://localhost:3000/Account"