import React from "react";
import { Container, Stack } from "react-bootstrap";


function CurrentBudget() {
    return(
        <Container>
            <h1>Current Budget page</h1>
        </Container>
    )
}

export default CurrentBudget;